import Vue from "vue";
import Vuex from "vuex";
import { environment } from './environment';
import _lodash from 'lodash';

let env = require('./environment')
if (process.env.NODE_ENV == 'production') {
  env = require('./environment.prod')
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: env.environment.domain,
    serverName: env.environment.serverName,
    buyConfig: {},
    enableOmiseFeature: false,
    changeAuthenticatorRequireCheck: true
  },
  mutations: {
    setBuyConfig(state, value) {
      state.buyConfig = value
    }
  }
});
