<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import DefaultLayout from "./layouts/default-layout.vue";
import UserLayout from "./layouts/user-layout.vue";
import PrintLayout from "./layouts/print-layout";
import BlankLayout from "./layouts/blank-layout";
import Vue from "vue";
import { EventBus } from "@/event-bus";

export default {
  components: {
    DefaultLayout,
    UserLayout,
    PrintLayout,
    BlankLayout,
  },
  data: () => ({
    // layout: "blank-layout",
  }),
  created() {
    EventBus.$on("updateLayout", (layout = null) => {
      this.setLayout(layout);
    });
    EventBus.$on("signIn", () => {
      this.signIn();
    });
    EventBus.$on("signOut", () => {
      this.signOut();
    });
  },
  computed: {
    layout() {
      let token = this.$cookies.get("user_token") || null;

      if (this.$route.meta.printLayout == true) {
        return "print-layout";
      }

      if (token) {
        return `user-layout`;
      } else {
        return `default-layout`;
      }
    },
  },
  mounted() {
    // this.setLayout();
  },
  beforeDestroy() {
    EventBus.$off("signIn");
    EventBus.$off("signOut");
    EventBus.$off("updateLayout");
  },
  methods: {
    setLayout(layout = null) {
      let token = this.$cookies.get("user_token") || null;

      if (layout != null) {
        // this.layout = layout;
        return;
      }

      if (token) {
        // this.layout = `user-layout`;
      } else {
        // this.layout = `default-layout`;
      }
    },
    async signIn() {
      this.setLayout();
    },
    async signOut() {
      this.$cookies.remove("user_token");
      this.$cookies.remove("user_info");
      this.setLayout();
      this.$router.replace({
        path: "/",
      });
    },
  },
};
</script>


<style>
.border-bottom-cus {
  border-bottom: 5px solid !important;
  color: rgba(0, 201, 167, 0.1);
}
</style>