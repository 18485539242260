<template>
  <div>
    <NavbarUser :refValue="ref"></NavbarUser>

    <router-view></router-view>

    <Footer @clicked="getRefName"></Footer>
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<script>
import { ArrowUpIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import NavbarUser from "@/components/navbar-user";
import Footer from "@/components/footer";

/**
 * Shop-cart component
 */
export default {
  data() {
    return {
      userAccountValue: 1000,
      storageValue: 50,
      liveValue: 2,
      counter: 0,
      list: [],
      overlay: false,
      overlayOpts: {},
      ref: "",
    };
  },
  components: {
    NavbarUser,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
  },
  computed: {
    totalValue() {
      return (
        parseInt(this.userAccountValue) +
        parseInt(this.storageValue) +
        parseInt(this.liveValue)
      );
    },
  },
  mounted() {
    this.userAccountValue = this.$route.query.user;
    this.storageValue = this.$route.query.storage;
    this.liveValue = this.$route.query.live;

    this.list = [
      {
        id: 1,
        image: "images/shop/product/s1.jpg",
        title: "User Account",
        // price: 255,
        qty: this.userAccountValue + " Student",
        total: this.userAccountValue,
      },
      {
        id: 2,
        image: "images/shop/product/s3.jpg",
        title: "Storage",
        // price: 520,
        qty: this.storageValue + " GB",
        total: this.storageValue,
      },
      {
        id: 3,
        image: "images/shop/product/s6.jpg",
        title: "Live",
        // price: 160,
        qty: this.liveValue + " Channel",
        total: this.liveValue,
      },
    ];
  },
  methods: {
    getRefName(value) {
      this.ref = value;
    },
    increase(val) {
      this.list.map((product) => {
        if (product.id === val) {
          product.qty += 1;
        }
      });
    },
    decrement(val) {
      this.list.map((product) => {
        if (product.id === val) {
          while (product.qty > 0) {
            product.qty -= 1;
          }
        }
      });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    proceedCheckout() {
      this.$router.push({
        name: "Payment",
      });
    },
  },
};
</script>
