import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/home.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: () => import('../views/pricing/pricing.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('../views/summary.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/finish/:ref',
    name: 'Finish',
    component: () => import('../views/finish.vue'),
    meta: {
      requiresAuth: true
    },
  },

  {
    path: '/forgetpass',
    name: 'Forgetpass',
    component: () => import('../views/login/forgetpass/forgetpass.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/register.vue')
    ,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/terms.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/payment.vue'),
    meta: {
      requiresAuth: true
    },

  },
  {
    path: '/feature',
    name: 'Feature',
    component: () => import('../views/feature/feature.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/about.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/contact.vue'),
    meta: {
      requiresAuth: false
    },
  },

  {
    path: '/library/howToLogin',
    component: () => import('../views/library/content/login/contentLogin.vue'),
  },
  {
    path: '/library/howToLoginUser',
    component: () => import('../views/library/contentNerdof/login/contentLoginUser.vue'),
  },
  {
    path: '/library/howToEditProfile',
    component: () => import('../views/library/contentNerdof/login/contentEditProfile.vue'),
  },
   {
    path: '/library/howToResetPassword',
    component: () => import('../views/library/contentNerdof/login/contentResetPassword.vue'),
  },

  {
    path: '/library/howToCreateUser',
    component: () => import('../views/library/content/userManagement/contentUserCreate.vue'),
  },

  {
    path: '/library/howToEditUser',
    component: () => import('../views/library/content/userManagement/contentUserEdit.vue'),
  },
  {
    path: '/library/howToDeleteUser',
    component: () => import('../views/library/content/userManagement/contentUserDelete.vue'),
  },
  {
    path: '/library/howToRestoreUser',
    component: () => import('../views/library/content/userManagement/contentUserRestore.vue'),
  },
  {
    path: '/library/howToAccessUser',
    component: () => import('../views/library/content/userManagement/contentUserAccess.vue'),
  },
  {
    path: '/library/howToChangeGroup',
    component: () => import('../views/library/content/userManagement/contentUserChangeGroup.vue'),
  },
  {
    path: '/library/howToViewActivity',
    component: () => import('../views/library/content/userManagement/contentUserViewActivity.vue'),
  },
  {
    path: '/library/howToSuspend',
    component: () => import('../views/library/content/userManagement/contentUserSuspend.vue'),
  },
  {
    path: '/library/howToActivate',
    component: () => import('../views/library/content/userManagement/contentUserActivate.vue'),
  },
  {
    path: '/library/howToDeleteUserAllGroup',
    component: () => import('../views/library/content/userManagement/contentUserAllGroupDelete.vue'),
  },
  {
    path: '/library/howToChangeUserAllGroup',
    component: () => import('../views/library/content/userManagement/contentUserAllGroupChangeGroup.vue'),
  },
  {
    path: '/library/howToTurnOffUserAllGroup',
    component: () => import('../views/library/content/userManagement/contentUserAllGroupTurnOffStatus.vue'),
  },
  {
    path: '/library/howToTurnOnUserAllGroup',
    component: () => import('../views/library/content/userManagement/contentUserAllGroupTurnOnStatus.vue'),
  },
  //End User

  {
    path: '/library/howToCreateCourse',
    component: () => import('../views/library/content/courseManagement/course/contentCourseCreate.vue'),
  },
  {
    path: '/library/howToEditCourse',
    component: () => import('../views/library/content/courseManagement/course/contentCourseEdit.vue'),
  },
  {
    path: '/library/howToPublishCourse',
    component: () => import('../views/library/content/courseManagement/course/contentCourseActive.vue'),
  },
  {
    path: '/library/howToDeleteCourse',
    component: () => import('../views/library/content/courseManagement/course/contentCourseDelete.vue'),
  },
  {
    path: '/library/howToRecycleCourse',
    component: () => import('../views/library/content/courseManagement/course/contentCourseRecycle.vue'),
  },
  {
    path: '/library/howToCreateSection',
    component: () => import('../views/library/content/courseManagement/section/contentCourseCreateSection.vue'),
  },
  {
    path: '/library/howToEditSection',
    component: () => import('../views/library/content/courseManagement/section/contentCourseEditSection.vue'),
  },
  {
    path: '/library/howToDeleteSection',
    component: () => import('../views/library/content/courseManagement/section/contentCourseDeleteSection.vue'),
  },
  {
    path: '/library/howToOrderingSection',
    component: () => import('../views/library/content/courseManagement/section/contentCourseOrderingSection.vue'),
  },
  {
    path: '/library/howToViewCourseReport',
    component: () => import('../views/library/content/courseManagement/report/contentCourseReport.vue'),
  },
  {
    path: '/library/howToViewLiveReport',
    component: () => import('../views/library/content/courseManagement/report/contentLiveReport.vue'),
  },
  {
    path: '/library/howToViewOndemandReport',
    component: () => import('../views/library/content/courseManagement/report/contentOndemandReport.vue'),
  },
  {
    path: '/library/howToViewExamReport',
    component: () => import('../views/library/content/courseManagement/report/contentExamReport.vue'),
  },
  {
    path: '/library/howToCreateLectureVideo',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureVideo.vue'),
  },
  {
    path: '/library/howToCreateLectureYoutube',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureYoutube.vue'),
  },
  {
    path: '/library/howToCreateLectureDocument',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureDocument.vue'),
  },
  {
    path: '/library/howToCreateLecturePhoto',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLecturePhoto.vue'),
  },
  {
    path: '/library/howToCreateLectureBook',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureBook.vue'),
  },
  {
    path: '/library/howToCreateLectureWebsite',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureWebsite.vue'),
  },
  {
    path: '/library/howToCreateLectureOtherFile',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureOtherFile.vue'),
  },
  {
    path: '/library/howToCreateLectureArticle',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureArticle.vue'),
  },
  {
    path: '/library/howToOrderingLecture',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureMove.vue'),
  },
  {
    path: '/library/howToCreateLectureExamination',
    component: () => import('../views/library/content/courseManagement/lecture/contentCourseLectureExam.vue'),
  },
  {
    path: '/library/howToCreateExamSingleChoice',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamSingleChoice.vue'),
  },
  {
    path: '/library/howToCreateExamMatching',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamMatching.vue'),
  },
  {
    path: '/library/howToCreateExamFillTheBlank',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamFillTheBlank.vue'),
  },

  {
    path: '/library/howToCreateExamTagLevel',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamTagLevelCreate.vue'),
  },
  {
    path: '/library/howToEditExamTagLevel',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamTagLevelEdit.vue'),
  },
  {
    path: '/library/howToDeleteExamTagLevel',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseLectureExamTagLevelDelete.vue'),
  },

  {
    path: '/library/howToCreateLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveCreate.vue'),
  },
  {
    path: '/library/howToEditLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveEdit.vue'),
  },
  {
    path: '/library/howToStartLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveStart.vue'),
  },
  {
    path: '/library/howToEndLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveEnd.vue'),
  },
  {
    path: '/library/howToDeleteLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveDelete.vue'),
  },
  {
    path: '/library/howToDeleteLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveDelete.vue'),
  },
  {
    path: '/library/howToAddImageLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddImage.vue'),
  },
  {
    path: '/library/howToAddPdfLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddPdf.vue'),
  },
  {
    path: '/library/howToAddPollLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddPoll.vue'),
  },
  {
    path: '/library/howToAddQuizLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddQuiz.vue'),
  },
  {
    path: '/library/howToAddUrlLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddUrl.vue'),
  },
  {
    path: '/library/howToAddNamecheckLive',
    component: () => import('../views/library/content/courseManagement/live/contentCourseLiveAddNameCheck.vue'),
  },




  {
    path: '/library/howToAddPricing',
    component: () => import('../views/library/content/courseManagement/pricing/contentPricingCreate.vue'),
  },
  {
    path: '/library/howToDeletePricing',
    component: () => import('../views/library/content/courseManagement/pricing/contentPricingDelete.vue'),
  },
  {
    path: '/library/howToEditPricing',
    component: () => import('../views/library/content/courseManagement/pricing/contentPricingEdit.vue'),
  },
  {
    path: '/library/howToChangeGroupPricing',
    component: () => import('../views/library/content/courseManagement/pricing/contentPricingGroup.vue'),
  },
  {
    path: '/library/howToPublishPricing',
    component: () => import('../views/library/content/courseManagement/pricing/contentPricingStatus.vue'),
  },
  {
    path: '/library/howToAddLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentLearnerCreate.vue'),
  },
  {
    path: '/library/howToAddLearnerFromCourse',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentLearnerCreateWithCourse.vue'),
  },
  {
    path: '/library/howToImportLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentLearnerCreateImport.vue'),
  },
  {
    path: '/library/howToDeleteLearnerFromGroup',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentLearnerDeleteGroup.vue'),
  },
  // {
  //   path: '/library/howToAddContentToCourse',
  //   component: () => import('../views/library/content/contentCourseEdit.vue'),
  // },
  {
    path: '/library/howToViewReportLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerViewReport.vue'),
  },
  {
    path: '/library/howToViewActivityLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerViewActivity.vue'),
  },
  {
    path: '/library/howToEditExpirationLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerEditExp.vue'),
  },
  {
    path: '/library/howToDeleteLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerDelete.vue'),
  },
  {
    path: '/library/howToEditScoreLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerEditScore.vue'),
  },
  {
    path: '/library/howToEditFaceDetectionLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerEditFaceDetection.vue'),
  },
  {
    path: '/library/howToDeleteHisExamLearner',
    component: () => import('../views/library/content/courseManagement/learnerManagement/contentCourseLearnerDeleteHisExam.vue'),
  },
  {
    path: '/library/howToCreateExamTypeMemoryGame',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamMemoryGame.vue'),
  },
  {
    path: '/library/howToCreateExamTypeCrosswordGame',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamCrosswordGame.vue'),
  },
  {
    path: '/library/howToCreateExamTypeWordSearchGame',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamWordSearchGame.vue'),
  },
  {
    path: '/library/howToCreateExamTypeArticle',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamArticle.vue'),
  },
  {
    path: '/library/howToCreateExamTypeMultiChoice',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamMultiChoice.vue'),
  },
  {
    path: '/library/howToOrderingExam',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamMove.vue'),
  },
  {
    path: '/library/howToAssignTagLevelToExam',
    component: () => import('../views/library/content/courseManagement/exam/contentCourseExamAssignTagLevel.vue'),
  },
  // {
  //   path: '/library/howToAddLearnerToCourse',
  //   component: () => import('../views/library/content/contentCourseAddLearner.vue'),
  // },
  // {
  //   path: '/library/howToCreateLiveToCourse',
  //   component: () => import('../views/library/content/contentCourseCreateLive.vue'),
  // },
  // {
  //   path: '/library/howToCreateBundleToCourse',
  //   component: () => import('../views/library/content/contentCourseCreateBundle.vue'),
  // },
  //End Course

  {
    path: '/library/howToAddCertificate',
    component: () => import('../views/library/content/courseManagement/certificate/contentCourseCertificateAdd.vue'),
  },
  //End Certificate

  {
    path: '/library/howToAccessibility',
    component: () => import('../views/library/content/courseManagement/accessibility/contentCourseAccessibility.vue'),
  },
  //End Accessibility

  {
    path: '/library/howToManageRole',
    component: () => import('../views/library/content/courseManagement/role/contentCourseRoleManage.vue'),
  },
  {
    path: '/library/howToCreateUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupCreate.vue'),
  },
  {
    path: '/library/howToEditUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupEdit.vue'),
  },
  {
    path: '/library/howToCreateChildUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupCreateChild.vue'),
  },
  {
    path: '/library/howToChangeRootUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupChangeRoot.vue'),
  },
  {
    path: '/library/howToDeleteUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupDelete.vue'),
  },
  {
    path: '/library/howToMoveUserGroup',
    component: () => import('../views/library/content/userGroupManagement/contentUserGroupMove.vue'),
  },
  //End User Group

  {
    path: '/library/howToCreateBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerCreate.vue'),
  },
  {
    path: '/library/howToEditBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerEdit.vue'),
  },
  {
    path: '/library/howToPublishBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerActive.vue'),
  },
  {
    path: '/library/howToDeleteBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerDelete.vue'),
  },
  {
    path: '/library/howToRestoreBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerRecycle.vue'),
  },
  {
    path: '/library/howToMoveBanner',
    component: () => import('../views/library/content/bannerManagement/contentBannerMove.vue'),
  },
  //End Banner

  {
    path: '/library/howToCreateCategory',
    component: () => import('../views/library/content/dataManagement/category/contentCategoryCreate.vue'),
  },
  {
    path: '/library/howToEditCategory',
    component: () => import('../views/library/content/dataManagement/category/contentCategoryEdit.vue'),
  },
  {
    path: '/library/howToPublishCategory',
    component: () => import('../views/library/content/dataManagement/category/contentCategoryActive.vue'),
  },
  {
    path: '/library/howToDeleteCategory',
    component: () => import('../views/library/content/dataManagement/category/contentCategoryDelete.vue'),
  },
  {
    path: '/library/howToRestoreCategory',
    component: () => import('../views/library/content/dataManagement/category/contentCategoryRecycle.vue'),
  },
  //End Category

  {
    path: '/library/howToCreateTag',
    component: () => import('../views/library/content/dataManagement/tag/contentTagCreate.vue'),
  },
  {
    path: '/library/howToEditTag',
    component: () => import('../views/library/content/dataManagement/tag/contentTagEdit.vue'),
  },
  {
    path: '/library/howToPublishTag',
    component: () => import('../views/library/content/dataManagement/tag/contentTagActive.vue'),
  },
  {
    path: '/library/howToDeleteTag',
    component: () => import('../views/library/content/dataManagement/tag/contentTagDelete.vue'),
  },
  {
    path: '/library/howToRestoreTag',
    component: () => import('../views/library/content/dataManagement/tag/contentTagRecycle.vue'),
  },
  //End Tag


  {
    path: '/library/howToCreateCompany',
    component: () => import('../views/library/content/companyManagement/contentCompanyCreate.vue'),
  },
  {
    path: '/library/howToAccessCompany',
    component: () => import('../views/library/content/companyManagement/contentCompanyAccess.vue'),
  },
  {
    path: '/library/howToMenuSettingCompany',
    component: () => import('../views/library/content/companyManagement/contentCompanyTabbar.vue'),
  },
  {
    path: '/library/howToSettingCompany',
    component: () => import('../views/library/content/companyManagement/contentCompanySetting.vue'),
  },
  {
    path: '/library/howToViewCourse',
    component: () => import('../views/library/contentNerdof/detailcourse/contentDetailCourse.vue'),
  },
  {
    path: '/library/howToViewLive',
    component: () => import('../views/library/contentNerdof/detailcourse/contentDetailLive.vue'),
  },
  {
    path: '/library/howToViewCourseVDO',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureVDO.vue'),
  },
  {
    path: '/library/howToViewCourseYoutube',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureYoutube.vue'),
  },
  {
    path: '/library/howToViewCourseDocument',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureDocument.vue'),
  },
  {
    path: '/library/howToViewCourseImage',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureImage.vue'),
  },
  {
    path: '/library/howToViewCourseBook',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureBook.vue'),
  },
  {
    path: '/library/howToViewCourseWeb',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureWeb.vue'),
  },
  {
    path: '/library/howToViewCourseOther',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureOther.vue'),
  },
  {
    path: '/library/howToViewCourseArticle',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureArticle.vue'),
  },
  {
    path: '/library/howToViewCourseExam',
    component: () => import('../views/library/contentNerdof/detailcourse/contentLectureExam.vue'),
  }, 
  {
    path: '/library/howToViewMyCourse',
    component: () => import('../views/library/contentNerdof/contentMyCourse.vue'),
  },
  {
    path: '/library/howToFavoriteCourse',
    component: () => import('../views/library/contentNerdof/contentFavoriteCourse.vue'),
  },
  {
    path: '/library/howToReviewCourse',
    component: () => import('../views/library/contentNerdof/contentReviewCourse.vue'),
  },
  {
    path: '/library/howToDiscussionBoard',
    component: () => import('../views/library/contentNerdof/contentDiscussionBoard.vue'),
  },


  //   //End Companyป





  {
    path: '/account/profile',
    name: 'Profile',
    component: () => import('../views/account/profile/profile.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/security',
    name: 'Security',
    component: () => import('../views/account/security/index.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/income-config',
    name: 'IncomeConfig',
    component: () => import('../views/account/income/config.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/billing',
    name: 'Transaction',
    component: () => import('../views/account/billing/billing.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/point',
    name: 'Point',
    component: () => import('../views/account/point/system-point.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/creditcard',
    name: 'Credit Card',
    component: () => import('../views/account/creditcard/creditcard.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/bank-account',
    name: 'BankAccount',
    component: () => import('../views/account/bank-account/form.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/sales',
    name: 'Sales',
    component: () => import('../views/account/sales/sales.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/balance',
    name: 'Balance',
    component: () => import('../views/account/balance/balance.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/point',
    name: 'CreditPoint',
    component: () => import('../views/account/point/system-point.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/account/receipt/:id',
    name: 'Receipt',
    component: () => import('../views/account/receipt/receipt.vue'),
    meta: {
      requiresAuth: true,
      printLayout: true
    },
  },
  {
    path: '/account/slip/:id',
    name: 'WithdrawSlip',
    component: () => import('../views/account/receipt/withdraw-slip.vue'),
    meta: {
      requiresAuth: true,
      printLayout: true
    },
  },
  // {
  //   path: '/library/howToCreateBanner',
  //   name: 'howToCreateBanner',
  //   component: () => import('../views/library/content/contentBannerCreate.vue'),
  //   meta: {
  //     requiresAuth: false
  //   },
  // },
  {
    path: '/',
    redirect: {
      path: '/home'
    },
  },
  {
    path: '*',
    redirect: {
      path: '/home'
    },
  },

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
