<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
  methods: {
    checkRefName(value) {
      this.$emit("clicked", value);
    },
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer"> DekNerd Co., Ltd. </a>
            <div class="mt-4">
              <address>
                <strong>Address: </strong>
                448 Rama 2 Road<br />
                Bang Mot Subdistrict, Chom Thong District, Bangkok, Thailand 10150
              </address>
            </div>
          </div>
          <!--end col -->

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <!-- <h4 class="text-light footer-head">Company</h4>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/page-aboutus" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> About
                  us</router-link
                >
              </li>
              <li>
                <router-link to="/page-services" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Services</router-link
                >
              </li>
              <li>
                <router-link to="/page-team" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Team</router-link
                >
              </li>
              <li>
                <router-link to="/page-pricing" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Pricing</router-link
                >
              </li>
              <li>
                <router-link to="/page-work-modern" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Project</router-link
                >
              </li>
              <li>
                <router-link to="/page-jobs" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Careers</router-link
                >
              </li>
              <li>
                <router-link to="/page-blog-grid" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Blog</router-link
                >
              </li>
            </ul> -->
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <!-- <h4 class="text-light footer-head">Usefull Links</h4>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/page-terms" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Terms of
                  Services</router-link
                >
              </li>
              <li>
                <router-link to="/page-privacy" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Privacy
                  Policy</router-link
                >
              </li>
              <li>
                <router-link to="/documentation" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Documentation</router-link
                >
              </li>
              <li>
                <router-link to="/changelog" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Changelog</router-link
                >
              </li>
              <li>
                <router-link to="/components" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Components</router-link
                >
              </li>
            </ul> -->
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h4 class="text-light footer-head">Menu</h4>
            <ul class="list-unstyled footer-list mt-4">
              <li @click="checkRefName('home')">
                <router-link to="/home" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Home</router-link
                >
              </li>
              <li @click="checkRefName('feature')">
                <router-link to="/feature" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Feature</router-link
                >
              </li>
              <li @click="checkRefName('libraryAdmin')">
                <router-link to="/library/howToLogin" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Library Admin
                  page</router-link
                >
              </li>
              <li @click="checkRefName('libraryUser')">
                <router-link to="/library/howToLoginUser" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Library Nerdof
                  page</router-link
                >
              </li>

              <li @click="checkRefName('pricing')">
                <router-link to="/calculator" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Pricing</router-link
                >
              </li>
              <li @click="checkRefName('contact')">
                <router-link to="/contact" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Contact</router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        Copyright &copy; 2021 NerdOf
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>