import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTheMask from 'vue-the-mask';

Vue.config.productionTip = true

import "@mdi/font/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'babel-polyfill'

import { BootstrapVue, BootstrapVueIcons, FormPlugin } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'
import VueCookies from 'vue-cookies';

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VueCompositionAPI from '@vue/composition-api'
import VueMask from 'v-mask'

Vue.use(VueCompositionAPI)
Vue.use(VueScrollTo, {
  duration: 1500,
  easing: "ease"
})

Vue.use(VueTheMask);
Vue.use(BootstrapVue)
Vue.use(FormPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(Scrollspy);
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)
Vue.use(VueCookies);
Vue.use(require('vue-moment'));

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Vue.$cookies.get('user_token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.filter('numFormat', numFormat(numeral));

//#region axios config
axios.defaults.baseURL = store.state.api;
axios.defaults.headers.common["server-name"] = store.state.serverName;
axios.interceptors.request.use((config) => {
  const accToken = Vue.$cookies.get('user_token');
  if (accToken !== null && accToken !== undefined) {
    config.headers['x-session-token'] = `${accToken}`;
  }
  return config;
}, (err) => {
  return Promise.reject(err);
});
Vue.use(VueAxios, axios);
//#endregion
Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
